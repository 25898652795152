import axios from 'axios';

let prefix = "/user-wallet"

const UserWalletApi = {
	
	getUserWithdrawalWalletHistory(payload){
		return axios.get( prefix + "/withdrawal-wallet-history", {params: payload})
	},
}

export default UserWalletApi;